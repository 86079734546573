import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import {HomePageText} from '../graphql/QueryById'

const Breadcrumbs = ({ productType, productName, nameEn }) => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const mn = currentLanguage === "mn"

  const productTypePure = productType
    ? productType.trim().toLowerCase()
    : undefined

  const type = productTypePure
    ? productTypePure.charAt(0).toUpperCase() + productTypePure.slice(1)
    : undefined

  const namePure = productName ? productName?.trim().toLowerCase() : undefined

  const nameCondition = namePure
    ? nameEn !== "wallpaper"
      ? namePure?.charAt(0).toUpperCase() + namePure?.slice(1)
      : namePure?.toUpperCase()
    : undefined

  // console.log(productTypePure)

  const name = namePure ? nameCondition : undefined

  return (
    type && (
      <div className="px-4 mb-6">
        <Link
          to="/"
          className="font-semibold text-gray-800 hover:text-black hover:underline"
        >
          {HomePageText()}
          {/* {mn ? "Нүүр хуудас" : "Home"} */}
        </Link>{" "}
        /{" "}
        <Link
          to={`/${nameEn}`}
          className="font-semibold text-gray-800 hover:text-black hover:underline"
        >
          {type}
        </Link>{" "}
        {name && <span className="">/ {name}</span>}
      </div>
    )
  )
}

export default Breadcrumbs
