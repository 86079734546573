import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import PageHeader from "./PageHeader"
import { useTranslation } from "react-i18next"
import Card from "../components/Card"
import WallpaperFooterLink from "../components/WallpaperFooterLink"
import ReactMarkdown from "react-markdown"
import CircleSubMenu from "../components/CircleSubMenu"
import CardModal from "../components/CardModal"
import Breadcrumbs from "../components/Breadcrumbs"
import PageTitle from "../components/PageTitle"

const TypeTemplate = ({
  pageContext,
  data: {
    menuInfo: { data },
    tableQuery: { nodes },
  },
}) => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const mn = currentLanguage === "mn"
  // console.log(pageContext)

  const [value, setValue] = useState(0)

  const menupath = data ?? undefined
  const nameMn = menupath?.nameMn ?? undefined
  const nameEn = menupath?.nameEn ?? undefined
  const menuName = mn ? nameMn : nameEn

  const textMn = menupath?.textMn ?? undefined
  const textEn = menupath?.textEn ?? undefined
  const text = mn ? textMn : textEn

  const menuSharpPath = menupath?.Image ?? undefined
  const menuSharp = menuSharpPath
    ? getImage(menuSharpPath.localFiles[0])
    : undefined
  const menuImage = menuSharp ? (
    <GatsbyImage image={menuSharp} alt={menuName} className="w-full" />
  ) : undefined

  const aguulga = nodes
    ? nodes.map((item, index) => {
        const path = item?.data ?? undefined
        const nameMn = path?.nameMn ?? undefined
        const nameEn = path?.nameEn ?? undefined
        const name = mn ? nameMn : nameEn
        const slug = nameEn ? nameEn.replace(/\s+/g, "-").toLowerCase() : "#"

        const colorHex = path?.hex ?? undefined
        const hex = colorHex
          ? colorHex.charAt(0) === "#"
            ? colorHex
            : "#" + colorHex
          : undefined
        const genColor = path?.genColor ?? undefined

        const textMn = path?.textMn ?? undefined
        const textEn = path?.textEn ?? undefined
        const text = mn ? textMn : textEn

        const sharpPath = path?.Image ?? undefined
        const sharp = sharpPath ? getImage(sharpPath.localFiles[0]) : undefined
        const image = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        const wallpath = path?.Wallpaper ?? undefined
        const wallpapers = wallpath?.map((item, index) => {
          const subpath = item?.data ?? undefined
          const name = subpath?.Name ?? undefined
          const productType = "wallpaper"

          const saleBeforeConverted = subpath?.Sale ?? undefined
          const sale = saleBeforeConverted
            ? saleBeforeConverted * 100 + "%"
            : undefined

          const newProduct = subpath?.New ?? undefined
          const hot = subpath?.Hot ?? undefined
          const modern = subpath?.Modern ?? undefined
          const best = subpath?.Bestseller ?? undefined

          const slug = name ? name.replace(/\s+/g, "-").toLowerCase() : "#"
          const subSharpPath = subpath?.Image ?? undefined
          const subSharp = subSharpPath
            ? getImage(subSharpPath.localFiles[0])
            : undefined
          const image = subSharp ? (
            <GatsbyImage image={subSharp} alt={name} className="w-full" />
          ) : undefined

          return {
            name,
            image,
            slug,
            productType,
            sale,
            newProduct,
            hot,
            modern,
            best,
          }
        })

        return {
          name,
          slug,
          image,
          wallpapers,
          hex,
          genColor,
        }
      })
    : undefined

  // console.log(aguulga)

  let [products, setProducts] = useState(null)

  const filteredAguulga = aguulga
    .filter(array => array.wallpapers)
    .sort(function (a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })

  const menuLength = filteredAguulga.length

  const menu = filteredAguulga.map((item, index) => {
    const name = item?.name ?? undefined
    const image = item?.image ?? undefined
    const slug = item?.slug ?? undefined
    const type = "wallpaper"
    const hex = item?.hex ?? undefined

    return (
      <div
        key={index}
        className="relative flex flex-col items-center justify-center mb-4 group"
      >
        <div
          className={`flex overflow-hidden rounded-full filter ring-1 ring-gray-300 ${
            index === value
              ? "ring-4 ring-offset-2 ring-brand z-10 transform scale-125"
              : ""
          } ${hex ? "sm:w-16 sm:h-16 h-12 w-12" : "sm:w-24 sm:h-24 h-16 w-16"}`}
          style={{
            backgroundColor: hex && hex,
            WebkitBackfaceVisibility: "hidden",
            MozBackfaceVisibility: "hidden",
            WebkitTransform: "translate3d(0, 0, 0)",
            MozTransform: "translate3d(0, 0, 0)",
          }}
          onClick={() => setValue(index)}
        >
          {image}
        </div>
        {name && (
          <div
            className={`absolute mt-2 leading-tight   group-hover:line-clamp-none text-center text-sm sm:text-base px-1  ${
              index === value ? "font-semibold" : ""
            } ${
              hex
                ? "sm:top-16 top-12 hidden group-hover:flex bg-white rounded shadow px-2 py-1 z-20 items-center"
                : "sm:top-24 top-16 line-clamp-2"
            }`}
          >
            {name}
          </div>
        )}
      </div>
    )
  })

  const filteredAguulgaWallpapers = filteredAguulga[value]?.wallpapers
    ? filteredAguulga[value]?.wallpapers?.sort(function (a, b) {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    : undefined

  // console.log(filteredAguulgaWallpapers)

  const walls = filteredAguulgaWallpapers?.map((item, index) => {
    // console.log(item)
    return <Card key={index} data={item} />
  })

  return (
    <Layout>
      {/* <PageHeader title={menuName} image={menuImage} text={text} tall /> */}
      <PageTitle title={menuName} text={text} />
      <div className="max-w-screen-lg mx-auto mt-10 ">
        <Breadcrumbs
          productType="wallpaper"
          nameEn="wallpaper"
          productName={menuName}
        />
        <div
          className={` mx-auto bg-white justify-evenly px-2 sm:px-10 border-b lg:border-none border-gray-100 z-10 relative pt-4 shadow-md bg-opacity-60 items-center ${
            pageContext.menuName === "Color"
              ? "grid grid-cols-6 sm:grid-cols-7 md:flex pb-1 pt-4 lg:pt-6 max-w-screen-lg lg:grid-cols-12 place-items-center"
              : // "flex max-w-screen-lg px-2 flex-wrap"
              menuLength === 1
              ? "grid grid-cols-1 pb-7 pt-2 lg:pt-6 max-w-xs"
              : menuLength === 2
              ? "grid grid-cols-2 pb-7 pt-2 lg:pt-6 max-w-md"
              : menuLength === 3
              ? "grid grid-cols-3 pb-10 pt-2 lg:pt-6 max-w-lg md:pb-12"
              : menuLength === 4
              ? "grid grid-cols-4 pb-7 pt-2 lg:pt-6 max-w-2xl"
              : menuLength === 5
              ? "grid grid-cols-5 pb-7 pt-2 lg:pt-6 max-w-screen-lg"
              : menuLength === 6
              ? "grid grid-cols-4 gap-x-4 gap-y-7 sm:grid-cols-5 md:grid-cols-6 pb-7 pt-2 lg:pt-6 max-w-screen-lg"
              : "grid grid-cols-4 gap-x-4 gap-y-7 sm:grid-cols-5 md:grid-cols-6 pb-7 pt-2 lg:pt-6 lg:grid-cols-7 max-w-screen-lg"
          } `}
        >
          {menu}
        </div>
      </div>
      <div className="grid max-w-screen-lg grid-cols-2 gap-6 px-4 pt-8 pb-32 mx-auto md:grid-cols-4 sm:grid-cols-3">
        {walls}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SpecialTableQuery($table: String!, $menuId: String!) {
    menuInfo: airtable(id: { eq: $menuId }) {
      data {
        table: Table_name__uurchilj_bolohgui_
        nameMn: Name_mongol
        nameEn: Name_english
        textEn: English_tailbar
        textMn: Mongol_tailbar
        Image: Menu_Image {
          localFiles {
            childImageSharp {
              gatsbyImageData(width: 1000, layout: CONSTRAINED)
            }
          }
        }
      }
    }
    tableQuery: allAirtable(filter: { table: { eq: $table } }) {
      nodes {
        data {
          nameEn: en
          nameMn: mn
          textMn: Mongol_tailbar
          textEn: English_tailbar
          hex: Hex_Color
          genColor: General_color
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(width: 1000, layout: CONSTRAINED)
              }
            }
          }
          Wallpaper {
            data {
              Name
              Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 400, layout: CONSTRAINED)
                  }
                }
              }
              Bestseller
              Hot
              New
              Modern
              Sale
            }
          }
        }
        id
      }
    }
  }
`

export default TypeTemplate
